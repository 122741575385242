import Basket10CILR from './B10CILR.dwg';
import Basket11CILR from './B11CILR.dwg';

const ClydeData = [
	{
		name: 'ASRS Retrieval System',
		documents: [
			{
				description: 'ASRS Retrieval System Ledger',
				type: 'Ledger',
				link: 'https://docs.google.com/spreadsheets/d/122LWT1xRBErDt3m7wuhPq4igdtdmh_6Uj3AHTweWtt4/edit#gid=205211224',
			},
		],
	},

	{
		name: 'Basket 10',
		documents: [
			{
				type: 'Ledger',
				description: 'Overall Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1hn_thUKxgKV8YV8ST2sMniqisbr8FFCd81yiUJVD9Ic/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Straightener / Feeder Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1lNyCjo8oh33VgJilZZeQIy2OlZnVqY9OU_cKdHFxIiA/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Cut-Off Press / Dies Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1r6snp5anK-i_Dct1g0in89V8dm7Uhva8809FpqW9-wY/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Roll Up Weld Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1PDVub5MWeNH8T_2lL8z0dXdAqjr3veuPGHcb32jFJOQ/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Fiber Laser Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1cyIPfe8B-oNEJdPjTUgGw5WzS6zzwPCuoC4MXYBODdo/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Fab Robot Laser Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1UgkJUKvMFY1pZnYwpdsfTgxoc_vsj-X2acEB2UXoT2U/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Fab Robot EOAT Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1FyFuS8KhXcWhck5eggqWDe-TNuJqm5GV8-NRTCwC9oI/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Spinform & Tooling Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1GGxob4CZJPvN9tlt4e1RCXSw3AhrdZpA21k9PA47Z3w/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Rotary Lance & Tooling Ledger',
				link: 'https://docs.google.com/spreadsheets/d/18KkseaaaW8Y4JpFl4EEe62ofxmPlK-g_9yikPd0fwbY/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Assembly Robot Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1r93b_QLyZycls57HF52z8tLP1emrUgGr_vCh2k_9s3Q/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Assembly Robot EOAT Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1Y3GyyVqFIfHTlrqofCK4HBYUdyHisTtES7GDjsSOAiA/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Pill Station Ledger',
				link: 'https://docs.google.com/spreadsheets/d/17jnDqAfwwt5yfXXnX7dR4S8sdLe3jBT1fhePnADdRI0/edit#gid=1355394232',
			},

			{
				type: 'SMP',
				description: 'SMP-38 - Allen Bradley Stratix 2000 Ethernet Switch Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ioHTLwT5k_87oU4d-pokEuGisUvrf3SxfOCKysPTZVM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-39 - Allen Bradley 1756 Series Ethernet Interface Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1KR4f1SDJev7xnBbV0H7O8aCBReLlArxx9PoZLliw20g/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-40 - Allen Bradley 1606XLS480E-3 Power Supply Replacement',
				link: 'https://docs.google.com/spreadsheets/d/13iKmEZWlGhOzm95B52fuhQUhyX_PoBBnn9bRXw9KrCA/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-41 - Allen Bradley PowerFlex 755 VFD Replacement',
				link: 'https://docs.google.com/spreadsheets/d/14ZBhSZzAtsgqhuHKDS-o7sNFTP3E-WD74kdDL3zc9xA/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-42 - Allen Bradley PowerFlex 750-Series Option Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/15Z2dV_nMuYdHYl4D0O9yRxzXB0JyhMy_3Vf4jEQ1hNM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-43 - Allen Bradley PowerFlex 750 Series Dual-Port Ethernet IP Option Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1HpwOAK_z33Gu_eSX0P4igWKOdU5bStdjw42WKtfNzUk/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-44 - Allen Bradley 1756-PB72 Power Supply Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ynnGS1r78Ak8BFBab2O5KUU0DWV4Q9NUV_LXNRx1glw/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-45 - Allen Bradley 1756-L61 Controller Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1WgQ75ZAATaFFiLZzcmBxN6Fm55arPuYAoC025d6yuR4/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-46 - Allen Bradley 1756 Series I/O Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1uu1aOHHm3snzVpFlW73QpwCY8kv52Ht4qbTjTjh_5wY/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-47 - Allen Bradley 1756-M0XSE SERCOS Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1wNetb5hJiwOjPkHIq-i9dpmvXqG-gSaTVe3fhFqtPxU/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-48 - ABB E1T Expansion Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1496IA3l4p5Yu1EV_Xc0kDxNdrubPGG_1ifOadWmgciM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-49 - Keyence LG-5001 Controller Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1YgbXibd77W1j8SqKcIH7YQNf83lyYriNYMjlaAVXEQE/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-50 - Allen Bradley PowerFlex 523 VFD Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1hWRpoGygKTKH6H9SXhzc477njaFdt90UGBJWL_mvbkI/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-51 - N-Tron 516TX Ethernet Switch Replacement',
				link: 'https://docs.google.com/spreadsheets/d/17Ysk05aRd3rN2l2kpIpgGRzywsbwkOZbg7QhxDIdSvE/edit#gid=435064206',
			},
			{
				type: 'SMP',
				description: 'SMP-52 - Allen Bradley 1794 Series Digital I/O Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1OJN0J_5-BQ_mt-UPtTINpXGknkf1Em4cLEuLBGRTATc/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-53 - Allen Bradley 1794-AENTR Communication Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1iOthmWbJTyKeTTCN895sgM7wCAsHAaLkinSSovtkxlE/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-54 - Allen Bradley PanelView Touchscreen Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1PVx89OD8CUe_EClUfdnzSpdzUY2wByxdRF4r4MP2Zp4/edit#gid=1098864097',
			},
			{
				type: 'SMP',
				description: 'SMP-55 - Allen Bradley PanelView Backup',
				link: 'https://docs.google.com/spreadsheets/d/15bQHeohsvwr-P0cYHUgMO-HlmD-QhEAhKTxiArEe3ck/edit#gid=1098864097',
			},
			{
				type: 'SMP',
				description: 'SMP-56 - ABB TINA 7A Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1EqOifPloAEThFNygFrBfN5cj9GWsjIs9PlFe8WyUsfU/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-57 - Master Pneumatics FD100-8 Filter Replacement',
				link: 'https://docs.google.com/spreadsheets/d/17j3smcdLQ__BcG9vfmw2OsydbVMBqo-DbODWEObG2Q0/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-58 - Fanuc R2000iB-165 Robot Motor Axis 1 Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1VP7eatzUQQmE7bbvaHEwyF8zXWNMDu4fyhAaj68Ro1c/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-59 - Fanuc R2000iB-165 Robot Motor Axis 2 Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1m2I1zXFBNTEhH2-7Oz89AfIt6coSlL5uZXpm8dUyOIU/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-60 - Fanuc R2000iB-165 Robot Motor Axis 3 Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1iOgoB1GYLly8Qhj_1GtfSn0U5JLBAPF2mcB72ZuEz_Q/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-61 - Fanuc R2000iB-165 Robot Motor Axis 4, 5, and 6 Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1YYQ7gRmaopqCEBPjTL0OvuyW0LMzRP7WTpQ_NVv_Bm8/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-62 - Fanuc R2000iB-165 Robot J1 Reducer Replacement',
				link: 'https://docs.google.com/spreadsheets/d/14mk_KNE5l2-WqXuGkgGfFo9GQw09fCSLeXeBeT0HbbI/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-63 - Fanuc R2000iB-165 Robot J2 Reducer Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1InCgH2PJ-rmzqulBnywZ3mEcs2Zf9S-FK5jts3m0FUY/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-64 - Fanuc R2000iB-165 Robot J3 Reducer Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1JP_0wiIyNud9Nvgj9f8xay5xUmcfiy6lKQEtjh_dTMg/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-65 - Fanuc R2000iB-165 Robot J4 Reducer Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1GF06d2Q5AyhGj097z8eu2I7GXE8CPQaioYUNKHh8Oug/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-66 - Fanuc R2000iB-165 Robot Battery Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1B9PyEDMGost8ctxukMOucItzGcTro_8gJhNThL6gxQ8/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-67 - Fanuc R2000iB Robot Balancer Bush Greasing',
				link: 'https://docs.google.com/spreadsheets/d/1Rj0-08r8Y3_aU51w1PZOCatzTvHeuEa7XyPRDGbCgII/edit#gid=1098864097',
			},
			{
				type: 'SMP',
				description: 'SMP-68 - Fanuc R2000iB Robot J1, J2, and J3 Grease Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1aFPjrA-ZsHwBFNtp6StQYN_mpx8YV6rm_O2aa9mZHZ0/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-69 - Fanuc R2000iB Robot J4 Grease Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1d80hTH1uRDqI-4B65YvSrCySY4DOv6gkL6stTTK6Cok/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-70 - SMC VQC4000 Air and Manifold Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ARmeKdj2OqmyJYF0KePqEhWKsSB02Gqosjk99nJBFxQ/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-71 - IFM Efector PN5204 Pressure Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1PJl02QcejeoB9ih-xRLRG1L2s-M0aalpybo3y8crNk8/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-72 - Osborn Yoke Roller Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1oNrou_jPdnySGSWFem3zV4iuauvp8FdQV1Y9fBOJ6lM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-73 - Straightener SMC MGPL Air Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1DY7lrr-s5tpTzPHtjCks_qH2QcLME0vZGB-Ijxn6k44/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-74 - Banner Q Series Laser Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1L2Ev-pJHAe9Th1ra7Mm-zUqdX7gCGYXUzE5DtUoDeEQ/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-75 - Turck Bi4 Inductive Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1abdDoOnRdjBb-jvaf4Xt6Eku1soP4batm6AxM6WsIyw/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-76 - Sola SDN 40-24-480C Power Supply Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1J98aRa-iTy6JCjWujLN1f-LzPjGmKER4M5jOKPBX5Pc/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-77 - Cutoff Press SMC MGPM Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1SMb-ehe_GQZhi_nson6Of46IsO9qBvVxhPRytuGPy2c/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-78 - Cutoff Press SMC MGPL Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1tveNjTNlHEf44c50P_hdJPD3SFGWSFu13lUj2s4xxxI/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-79 - Cutoff Press Parker Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1QtznzceAP06KBOAaOXplQtorJvsHOq5TToLDlkZjcF8/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-80 - SMC EX600-SEN1 Ethernet/IP Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ryhXohrcqHFWkzOPVg_Mfx-FA1mNV-qFzMagZB8wtoc/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-81 - Fiber Connector Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1MB8n3N2JD4EMlG7HxTKdzk-CVb_nsjI-jHGMKAsdqI0/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-82 - LaserNet Event Log Download',
				link: 'https://docs.google.com/spreadsheets/d/1A40wmXBzpuNWzbZAciaYrvIuY2ePFdHlUzFJTHaCw2w/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-83 - LaserNet Log Download',
				link: 'https://docs.google.com/spreadsheets/d/1OmEerJDTLhjLJr5BDoxKlT9n3MEwVxWX7r9IGBVsvyk/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-84 - Laser Cutter Firmware Update',
				link: 'https://docs.google.com/spreadsheets/d/1Cyhr3UpMAjnao-7l9mZpSOf7GuiRI1b-QQvxrFSBDIM/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-85 - Allen Bradley 8-Channel ArmorBlock Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1JWq0iWLNMi_e5XgijkIi3mRksl2nb6nC90ykVFFaLXU/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-86 - Roll Up Upper Weld SMC NCMR Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1Mq4LnXpou6EpXobSCIv36uGHGlfYFkPtFpnTB0EsdvQ/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-87 - Fairlane Rest Pad Replacement',
				link: 'https://docs.google.com/spreadsheets/d/14_9w-dCa-9T89cYmGVg7snUk3vrJFrxD70xiHjFPRyE/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-88 - Upper Weld SMC MGPL32TN-250Z Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1OBjbqCeBy6yP7QS4t8J6xbeaeIweJa-2ulMLlU5XYs0/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-89 - Upper Weld SMC MGPL25TN-20Z Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1fDvO4mhFA_3PxIAyZp8W7Jk2MkhrJSxQLyXJwHp0zCM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-90 - DeStaCo L81 Clamp Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1jsAdz_oVKhD3vHODM6QMErP_6gv7mmaFgnbVoGuxD28/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-91 - Keyence Laser Sensor Head Backup and Restore',
				link: 'https://docs.google.com/spreadsheets/d/1LPNggXIqwvDRwRpaiKjn4CJBNlaYwa5Bl4_nFG6-zns/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-92 - Keyence Laser Sensor Head Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1d6Kw-pSu9_oH7lLb-sm6CFtr95IpkgP4z6lvmnh7J04/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-93 - Lower Weld Parker Air Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1dISZRc2KLm5Rd_h-ienxCiqKm7swIJyI-z2ZRg7BuGg/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-94 - Enidine Shock Absorber Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1KYiA-8iRBVT0PQSpjPL4eLY6vl2NOO9GQxqyMHAuG8c/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-95 - IFM Efector OPL200 Angle Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1pDHTieFPZA0ESN8o41iev4U8gwdFu3Nrydf2nL5znPk/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-96 - Stilson Roller Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1AUGbmJyWCwPNELoA4bqV20x1qXThlkZYBgN5HnK64bM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-97 - Crowder Pusher Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1LJ5srLDVGaX94L3IOEG-Ojwz9kDl6DI3StlUAbQV8qQ/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-98 - Misumi Cam Follower Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1KyjnRze8Z8Z0mxOejpWXgmtg11QetgiwSUYUCPCLfoc/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-99 - Lower Weld SMC NCQ2D40 Compact Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1YsPoZAEa0ogkiD_apTes6t0Iet88kschQ25Zpe6EJVU/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-100 - Weld Roll Up Transfer SMC NCQ2D40 Compact Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1-iAwnOe_qKsk_i-CAJ1YiAwPAn_GAJGcR3k9FJUBwCo/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-101 - Weld Roll Up Transfer Belt Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1T3w0nWps_idyttZJFTuvx3YD9Sea-xNhF53AnKY4yCg/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-102 - Series 700 Boston Gearbox Oil Change',
				link: 'https://docs.google.com/spreadsheets/d/1V9Do4xeQUNwrSROgGy7nFZ3BhHSKzNvPXMc5ceDg3oA/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-103 - Fabrication Robot Fairlane Rest Pad Replacement',
				link: 'https://docs.google.com/spreadsheets/d/19djlzAtIUD8duCvaOgP9wTRFagSaZI7O5QtUhaPevD4/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-104 - Fabrication Robot Small Gripper Pad Replacement',
				link: 'https://docs.google.com/spreadsheets/d/13pKtUVRgG0_1xUalXwCuMo2NJNxH9ATARzdjKyu2TKU/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-105 - Fabrication Robot Gripper Pad Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1KYVJ9kcWfKYqHhSceu6LK5KnbFJg-P_anFxG_wcpo64/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-106 - Fabrication Robot Suction Cup Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1RdClBYX8NPH8R4UVRCsUIAs98OAp77zwFFHAH_lzYi0/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-107 - Fabrication Robot Turck Bi15U Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1drT6VMsvuUogfm1tOm0rIjlo6cjudeb_wGtJ08sM3lw/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-108 - Fabrication Robot SMC RSDQA50 Stop Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1amxv2JhWeTxsiSTxUJdO1mDglGoGMbN-akDSmvZjnzA/edit#gid=1569324226',
			},
			{
				type: 'SMP',
				description: 'SMP-109 - Fabrication Robot PHD Gripper Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1vYSwGJab5ulPCWxVB9Gzl7n0scv9RYbvdgV4cYlVLYQ/edit#gid=1569324226',
			},
			{
				type: 'SMP',
				description: 'SMP-110 - Assembly Robot Gripper Pad Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1FNdEfodR3EAj3gdBhyhin4IjrBlnq-5pXnNujuAf3rg/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-111 - Assembly Robot Turck Bi4 Inductive Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/11gK_Kj6uFp0yoxyb4RNI-l5WUVN86GsNtHB6V0Hq6yw/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-112 - Assembly Robot Fairlane Rest Pad Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1JhHJwUa3gFPaL0eJ0Rb_nQ5cJjLyRgrQHCCI0u-EtZo/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-113 - Rough Top Belting Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1pIkLkmCCQMhc6F5nBk3ckhH235GeU3_bNa_Ew-t0dNI/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-114 - Assembly Robot PHD Gripper Replacement',
				link: 'https://docs.google.com/spreadsheets/d/11NC6wuiz1PXduu2llzmykE71qL2CUSPSebyjfxMECvQ/edit#gid=1569324226',
			},
			{
				type: 'SMP',
				description: 'SMP-115 - Spinform Turck Bi4 Inductive Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/10IgkSzhMVbrL6uDHPGw_fWivUCMGEwvLiWesB63SiDo/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-116 - Spinform Memtech Brush Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1DJkbFBxuO8XFAUQa4pgy4GPeGkezG5Wr45kuCKJkd50/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-117 - Spinform Rockwell MPAI-B4150RM34A Cylinder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1wiWP5vC1P88GO053LmPraVgzC9oj79oCj4TnEfgZ1yw/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-118 - Spinform Baldor ZDFR Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1GVgC9mM9PFbo_E44X_9AwW10HAWUGKyW7fYKdzt0Z-8/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-119 - Spinform Spur Gear Replacement',
				link: 'https://docs.google.com/spreadsheets/d/11twQMTxgMKbhFC563RXzwRHLMj1T8pSAOEpp29agpH8/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-120 - Rotary Lance Sew Gear Motor Oil Change',
				link: 'https://docs.google.com/spreadsheets/d/1A5T8RRQiEMdRUc0H5zM4a95b2j1h4ueZTd-U6wO7QVM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-121 - Rotary Lance Sew Gear Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1pxGgkj19wJK1YktTKfA4QGAzVPKnSe42jjYi_2eUwXc/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-122 - Rotary Lance Fairlane Rest Pad Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1H5vHFBsLijWBkXv6j9y-l0tetW8MQC8sdDshi4Dz2jA/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-123 - Rotary Lance Gates Belt Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1uqREabGeLti-cSKMLEZNXXBMyHWg7Fku_JdiF98bGBI/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-124 - Allen Bradley 844D Encoder Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1w24OFLSPpHl4teJnFpXRi3utsM2qk6JWZ_JZdPbwwoU/edit#gid=1770907928',
			},
			{
				type: 'SMP',
				description: 'SMP-125 - Rotary Lance Gates Timing Sprocket Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ntqmZgpgfmMI1sxa6TxZQeySOxXB0-YkUWrOFm84hDI/edit#gid=1770907928',
			},
			{
				type: 'SMP',
				description: 'SMP-126 - Rotary Lance Browning Spur Gear Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1GC-uFUjfGi_AEEiI3EWMGFNUKG--hV9gaILAZfV0NxE/edit#gid=1770907928',
			},
			{
				type: 'SMP',
				description: 'SMP-127 - Rotary Lance SKF Radial Bearing Replacement',
				link: 'https://docs.google.com/spreadsheets/d/15OZnHn4HeZ3rfJ2uqusuW4POLc7mfWZfOr3jpfVk_K4/edit#gid=1770907928',
			},

			{
				type: 'SOP',
				description: 'C01: Cleaning The Fiber Laser Station',
				link: 'https://docs.google.com/spreadsheets/d/1jryYutB5KjU06lllB8JEyAJVD8DWenyyL_tbbIBMKtk/edit?ts=60366a91#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C04: 21.271: Polish Weld Datums and Weld Clamps North & South',
				link: 'https://docs.google.com/spreadsheets/d/1xEGoIK_qELwxFy9HZJZTlGWjJXTHm8N_ChnHxvc1Zlo/edit?ts=603d0033#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C05: 21.209: Soot Removal from Fiber Laser Enclosure',
				link: 'https://docs.google.com/spreadsheets/d/1FwJ8-8TyFuZIR9bJNziMgmzSeX2KYrvtFCE2amPJTek/edit?ts=60369175#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C06: 21.270: Clean Weld Monitor Sensor',
				link: 'https://docs.google.com/spreadsheets/d/1S-qJuOPcZZVFU2Y6SdUWw5RwzKXCQ4OoJ1-wrTmx4z8/edit?disco=AAAAH84HLII&usp_dm=false#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C07: Cleaning The Straightener Feeder',
				link: 'https://docs.google.com/spreadsheets/d/17KVPO0lOVqrEzIq56Q0Tut_6GC0iAaqGfrVRKerH77U/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C08: Cleaning The Perfecto Cabinet',
				link: 'https://docs.google.com/spreadsheets/d/16Gtipp6CB1CtEyILdchGDGiJRnZLVJwg8sFeDuLliNA/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C09: Cleaning The Cutoff Press Dies',
				link: 'https://docs.google.com/spreadsheets/d/1Dy-ZEMTZIQTHzKvlEfrk9rpI11ZsWy_fEfsTCQ4AVXk/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C10: Cleaning Roll Up Weld',
				link: 'https://docs.google.com/spreadsheets/d/1kpBwXeKFokS4Vx9QgqLeXMu_neq95Lm_zX5AR4VFd0Y/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C11: Cleaning Spinform & Tooling',
				link: 'https://docs.google.com/spreadsheets/d/1YP3oWCoFiRpAvzJp0tUlx1inwVhyJqEwTD2gcgF9Bkg/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C12: Cleaning Rotary Lance & Tooling',
				link: 'https://docs.google.com/spreadsheets/d/1zMQKWYIZVyrntgGbyPfJTu4plWNs4J8j31ZAewLi6TA/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C13: Cleaning Pill Station',
				link: 'https://docs.google.com/spreadsheets/d/1mZbMLsePJkeUAjN9XjsGsLx4Zz95uWItibq_I0ouaIU/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C14: Cleaning Assembly Robot EOAT',
				link: 'https://docs.google.com/spreadsheets/d/1CoeinLg1_Y5JNwZAspkO538x9vorYTJkHd_BgCdwsU8/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C15: Cleaning Fabrication Robot EOAT',
				link: 'https://docs.google.com/spreadsheets/d/1QjaXJWmQtx0RgIIgBqnVW7rQnw4UEk7dTaeQatqFdJs/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C16: Cleaning Fanuc 2000iC Robots',
				link: 'https://docs.google.com/spreadsheets/d/1IWEPEZrNrApSp_RG4fIlGF6bjqyEzxVmlP77uMAbihI/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'C17: Cleaning The Main Electrical Cabinet',
				link: 'https://docs.google.com/spreadsheets/d/1P3cCSphYuDmLRk6l__QmmaAU5TW2ki0FoYAJshiQ7fg/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I01: Inspecting Laser Weld Head Lens for Contamination',
				link: 'https://docs.google.com/spreadsheets/d/1p-iJzQyQAaZCkAr50TUb7BHwpWOPFcIejGWoHZA16UU/edit?ts=60366a98#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I02: Fiber Laser Station Daily Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1vufyNPfrVYrabXzRLVmfGibbJ2cmFY2PiyyIlcQK9v8/edit?ts=60366bf1#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I03: Inspecting The Dust Collector',
				link: 'https://docs.google.com/spreadsheets/d/1pAquUG-oknsuq5bdU1AgwUqo4Gx1Ixqw3ntutQ7UQKk/edit?usp=sharing',
			},

			{
				type: 'SOP',
				description: 'I04: Fiber Laser Station Monthly Inspection',
				link: 'https://docs.google.com/spreadsheets/d/14wcZrgLJg1faabWR2RK4G7dG9LPq2mhC0EeNmbv7R50/edit?ts=60366d70#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I05: SOP.21.231: Upper Datum #1 and #2 Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1vKCUGqClLllxGBwWFOYDwEL-05gmHqzdT40iAoNs--k/edit?ts=6038e9a9#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I06: 20.652: Running Crosshair Alignment',
				link: 'https://docs.google.com/spreadsheets/d/10pzpKuQGFvyORdQJG5DWx9zd8BmrcuAQ2oWCCC7WOk0/edit?ts=60379151#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I07: Dust Collector Retightening',
				link: 'https://docs.google.com/spreadsheets/d/1pUIzgk0KCoxKYrZ3xuytjN1RBRk1FOKIDPSRcC5j4uk/edit?ts=60366d7b#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I08: Laser Chiller Retightening',
				link: 'https://docs.google.com/spreadsheets/d/1iU3feYWu2w0hDvn-sYEfjZjYyXmg15gBH3Z1cplMvFA/edit?ts=60366d7e#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I11: V-Grooved Yoke Roller Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1Kt5n96dwr5AYj_-Z-7srUQPSsQFpspOr-1KgM4q4lXA/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I12: SMC Cylinder Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1ieZpbgDunuwYQ8WEuDQOTvnyZj4uUG5qL3CB4IM2-zA/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I13: Straightener Feeder Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1DCYt8o8KoveysFW1CNN857OrANSNznhFs_DgkJGWyh8/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I14: Perfecto Cabinet Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1L5yn-di7AIuY8X6VSFGJ4Oz3_8ltbyK0LdWPg_jt6IY/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I15: Banner Photoelectric Sensor Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1jSsl38Z1u9LrCs974RRd6hZOAN2PvMksxW12t8xCCrc/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I16: Parker Air Cylinder Inspection',
				link: 'https://docs.google.com/spreadsheets/d/10SE6USws3w99y64F5AdGeY98Dr_Ao8rDAbdbR655Mxc/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I17: Cutoff Press/Dies Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1gs93YOUyiXMQtm0aKGw-oNZ85dtLNowGNGN93l9AWFg/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I18: Soft Start Valve Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1EXHV05B7vQ_XXie3FrdnlODem1PUmYTeL55GuHc69As/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I19: IFM Efector Photoelectric Sensor Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1rpB_QIlMiN6K0qSEiuDFZmAslDwivqFWlZzn-KbDq7E/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I20: Roll Up Weld Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1lrxS-5bgCRp1rGCLZN8UzMUEv2ZiDYC-TACFDJ149_o/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I21: Spinform & Tooling Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1jaZ8aA8RHGejStq4PcM7lMibzvxcrWLDnd3UUhWDtEs/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I22: Rotary Lance & Tooling Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1XMh1--rMV2dbLlccWL7aYuYuBZe7ZVYR8jkF7W5bkvc/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I23: Pill Station Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1Io3B9_jcEKb9zv8bO_bxNNQW5zBRGqP2lL-2Bh5QPzI/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I24: Pill Station Wittenstein Gearbox Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1qoTO69k5J4T7Vb1tMamL-y8VMA4F0zlieB809TTBSA0/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I25: Assembly Robot EOAT Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1OkJE-5Ks0zsa7eT7c58Opqy72szY27yJxObjZr_enYw/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I26: Fabrication Robot EOAT Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1NOdsqpyuS0ejmFFBP9hxT2SkKHZ2c5FnCP11vkMjjhs/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'I27: Fanuc 2000iC Robot Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1J77qsj6-u0AchUyxREwdFCbCksff62zQ_OFVlK9WRPA/edit#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'L01: Fiber Laser Rail Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1QLSVhVucHBF_JlO1_Zy7dB-bTt6GZSCk2uDe3gY9ipY/edit?ts=60366d73#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'L02: Dust Collector Fan Motor Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/19W61RE5VWZrWiXJ1pibjSeKTfvlNOIu__HWxv_qp49s/edit?ts=60366d77#gid=1791550664',
			},

			{
				type: 'SOP',
				description: 'L03: IKO Slide Unit Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1jNnU15Ih1ct9TsQ4xlnjCWWli6hDWBZuzffgXNSmJI0/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L04: Allen Bradley Electric Cylinder Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/14kZAkOSHf0IGhEC1trcWZE2YByP9gkIRlmTqTrDEdls/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L05: Duff Norton Ballscrew Jack Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1mR5FcLDSPWAVJT-EETYDKPLjBr3ILjPcLUAkkaZiuCA/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L06: Pill Station Linear Bearing Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1sWSzpJ2rR-OQArI_j0UelFFqcgVjoJ00EkDx39FJ-Yc/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L07: Checking Oil Fill Level in Boston Gearbox',
				link: 'https://docs.google.com/spreadsheets/d/1zDggcyZeVNlb9GbpjxDBIYJ48Lm4HBdxQq-dK8gf8cc/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L08: Pill Station Pillow Block Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1JOcZrH_vkAsBQmXOzL0SgOvp9KPyGj5D2VETJqUlQ2Y/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L09: Pill Station Flange Bearing Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1TqNehW0jeYKk90J5r6AX4_7s_KQbSUFU_iMSyPqooiU/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L10: Fanuc R2000iC Robot Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1bjAsufC02K8jWtmltWe33dLvZ7p2lVWXx6aNNcyH2n4/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L11: Cut Press Lamina Bushing Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/1Uu7kktkFBta1HfpTmFfeTsyJzWqLA6f3bgKaXeEXNnU/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'S01: Window Safety Interlock Check',
				link: 'https://docs.google.com/spreadsheets/d/1wpVyeSw_KQmRLQqlOn9bwYvGfVtne9woqFNXmu1LA4E/edit?ts=60366d8f#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'S02: Laser System Emergency Stop Test',
				link: 'https://docs.google.com/spreadsheets/d/1c3FWXEScVFC_n_xS-Ao27WOChSVjv21wSigQJM2cN2I/edit?ts=60366da1#gid=1791550664',
			},

			{
				type: 'Manual',
				description: 'Operation and Maintenance Manual',
				link: 'https://www.interactivetrainingsystems.com/whirlpool/clyde/Basket_10/manual/Whirlpool_Advantage_Basket_Wrapper_Line_Manual.pdf',
			},
			{
				type: 'CILR',
				description: 'Basket 10 CILR Map',
				link: Basket10CILR,
			},

			{
				type: 'LubeMap',
				description: 'Basket 10 Lubrication Map',
				link: 'https://docs.google.com/spreadsheets/d/1fA4lGf1Ob469unzgAzBKnx3CPr0ngcCNNaLTbUP8Q-g/edit#gid=1783877841',
			},

			{
				type: 'Calendar',
				description: 'Basket 10 AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1prabsgywFlZHOzPfBO1HAXjVzbgMSYBfQTHTr6lxeuU/edit#gid=704714877',
			},
		],
	},

	{
		name: 'Basket 11',
		documents: [
			{
				type: 'Ledger',
				description: 'Basket 11 Overall Ledger',
				link: 'https://docs.google.com/spreadsheets/d/15086NfpMz4goVlw1FCinQk2heZlkY_XDyD1mDJt1O4k/edit#gid=1355394232',
			},

			{
				type: 'Ledger',
				description: 'Base Autoload Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1uQuATnn5eTSqhhqJ_K4j3BaDPpgqtnw4c9RktBd9g8E/edit#gid=1355394232',
			},
			{
				type: 'Ledger',
				description: 'Infeed Through Conveyor Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1bD8yTRWKuTsXQd9q8f3pfqPHuXlZiS2wXi1qdAi3n90/edit#gid=1355394232',
			},
			{
				type: 'Ledger',
				description: 'Base / Balancing Ring Conveyor Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1a71fLyEq8kGigeB4Z81G9d-p5622FRP5x0qFHjUW8-w/edit#gid=1355394232',
			},
			{
				type: 'Ledger',
				description: 'Basket Takeaway Conveyor Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1zOJkjKugDf6slskYJrAv2rK9IRx3PcQQWkx5Fu7zTB8/edit#gid=1355394232',
			},
			{
				type: 'Ledger',
				description: 'Conveyor Control Cabinet',
				link: 'https://docs.google.com/spreadsheets/d/1PDr6f3wSA6Y2ofr6JshIoDmPDfocVqO6ZhK1pY2p6qM/edit#gid=1355394232',
			},

			{
				type: 'SMP',
				description: 'SMP-01 - 90 Degree Rotate PowerTwist Belt Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1cqaNpcaE2Vm0-GBH_-qkqSI-bzsCgB4E-ZstZdT30Ew/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-02 - 90 Degree Rotate Flange Bearing Replacement',
				link: 'https://docs.google.com/spreadsheets/d/11hfLalUXJvOBKtMT57HFtnAHXTB3q6a4HKkeBwPdq4U/edit#gid=452009389',
			},
			{
				type: 'SMP',
				description: 'SMP-03 - 90 Degree Rotate Idler Hex Bearing Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1-Nht3Cf-oaEcbGntA5IqGd-zB72uXnoFZSwVCX-JADk/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-04 - 90 Degree Rotate Masterdrive 3/4" Sheave Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1tkXG7-_Sr1SyUFwx6udOzxfma5pvY6HMM1IU2k09EYU/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-05 - 90 Degree Rotate Masterdrive 1" Sheave Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1fnHKrbdvbsF377yDnmWYqKeVWDMHvcsjWcfjypSS6es/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-06 - 90 Degree Rotate Goodyear Air Spring Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1QXgpJgFl_QD9nyqIywBkr0nbUHOCq8w7pLnFmIGB19A/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-07 - SMC Series SY9 Solenoid Valve Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1judjBDWsSRAVy4i91UX6pxTBy_4dIf_euJRClAdSEGw/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-08 - Interroll EC5000 Power Roller Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1BlJ1GPiAsvQuok3wY-EW0XeVLTpmELjbeFuzgOSdpws/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-09 - Carry Roller Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1JbmmvIWG8LKc6ne96mZCVtoUIAB6hJLd4y_09AWQzYQ/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-10 - Lift Gate Gas Spring Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1VmQAwrX5auxAQU_VEzxUCAmoEDHDV7tBesgKjXMz9Cg/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-11 - Rubber Band Belt Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1MBbMDUGu-Xcb_F-2I7GQZZ4lsgg9jOmulyBt0ChGmtM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-12 - Lift Gate Outer Flange Bearing Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1s5tLg4-BTdH85UeYfOxbrFGUchtqoKspHMmnWibhqTw/edit#gid=55643247',
			},
			{
				type: 'SMP',
				description: 'SMP-13 - Lift Gate Inner Flange Bearing Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1urupruBiaAruMmYojBfrrGhJwRgkt6-iV8Zn95UUppQ/edit#gid=1362580563',
			},
			{
				type: 'SMP',
				description: 'SMP-14 - SICK Photoelectric Sensor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1l_GzLL5WXIhUfOd2zvsgYL-GrV2IDrhGiStaHLADQSQ/edit#gid=1362580563',
			},
			{
				type: 'SMP',
				description: 'SMP-15 - SICK Photoelectric Sensor Alignment',
				link: 'https://docs.google.com/spreadsheets/d/17vW30QcYv0h9aPEdUefJhF7z2uEB-2GmmFQsSjqaeFg/edit#gid=1362580563',
			},
			{
				type: 'SMP',
				description: 'SMP-16 - Interroll MultiControl Unit Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1Gq-LomjjuLghKpIJIqWxvo3eyrJbalXfn3Y4SmKprmA/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-17 - Interroll MultiControl Unit Backup',
				link: 'https://docs.google.com/spreadsheets/d/1908NbLRAMff0gFsEPLrMoGgy0PhDmPwWLO7lg8QrUCc/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-18 - SEW WA20 Gear Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1jwQWWO3DtkJnY2v-4nKVKrNKPVq1EkmOLMhhf9Zo8KQ/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-19 - 90 Degree Rotate Gear Motor Oil Change',
				link: 'https://docs.google.com/spreadsheets/d/1kvCqNa8nEH82JUmoCsnEp1ja3fO1I-Hqbj3C5shNXeQ/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-20 - Rough Top Conveyor Gear Motor Oil Change',
				link: 'https://docs.google.com/spreadsheets/d/1Owj5xmRVNcpOz9KDUfNTsb_JokGQd5YvgTs5tl0_sKs/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-21 - Infeed Through Conveyor Gear Motor Replacement',
				link: 'https://docs.google.com/spreadsheets/d/11jnlPr5SBZI2To-Fx9YDc3q6JFlsxNlgAMgBfH6ZTlU/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-22 - Infeed Through Conveyor Gear Motor Oil Change',
				link: 'https://docs.google.com/spreadsheets/d/18z47FmUfRaTlYS2zky2LhXNi7LtIek2i1vnJc-GrVLs/edit#gid=572593962',
			},
			{
				type: 'SMP',
				description: 'SMP-23 - Infeed Through Conveyor Drive Chain Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ZLxp1_2533fLAiEc1PvkyzQo7IKqw1955ORe3Kn9Swc/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-24 - Infeed Through Conveyor Drive Sprocket Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1PfoFV7CBhIXijAyjQfaDyCyDWHmiG2OqNCDN7r8xHow/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-25 - Infeed Through Conveyor Flange Bearing Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1FDkG7X65_gLpp_FIxd1zV7ycaT6-75EG8GjIaIcyVXM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-26 - Infeed Through Conveyor Belt (Series 900) Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1nT8hG0oljIn-YgqjUtHZyUuxTrQ-KWIUOdd6M9BrXqU/edit#gid=1894271697',
			},
			{
				type: 'SMP',
				description: 'SMP-27 - Allen Bradley 16-Channel ArmorBlock Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1V8tXxAxCrtqRExWf-zgRjza48-yOdEoPWLLOsT8ADIA/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-28 - 2-Ply Rough Top Conveyor Belt Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1KY-XOHnBI21sTiIdZbsKyobQ0M7BO1apoA3c0fDggpY/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-29 - Drive Pulley Replacement',
				link: 'https://docs.google.com/spreadsheets/d/107iOSg9SE9PIHQ4ChKgpIQ-A5uf7IBrHZSOzUsECtk4/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-30 - Tail Pulley Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1sKupTZhoNwvC-Uq_8iSCxgj129k5Ch7M2HVPjBRqqM0/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-31 - KML Flange Bearing (SBFL) Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1rMc0ZDGxVZWcg1s75UyIJQRyFxb2H1uXNauVJEDMADg/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-32 - Allen Bradley 1734-AENTR Communication Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1kNVUWqQky_P2chwQjyTjdJ6I1NMM3gkNOagtRTit8yw/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-33 - Allen Bradley 1734 Series I/O Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1sQiF2Pzzj9kPBWZDkzSphe-4CdV68yCHlJXjcPuR2jc/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-34 - Allen Bradley Safety Guardmaster Relay Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1AtzE7H8qjbVZL12zqNjpSMln_s-xSexytUVzNEd1_GI/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-35 - Allen Bradley 1606XLS960E 24VDC Power Supply Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1cNjEPG3ub9BFiBHorq1JwAQvPFKO1mgjMQ8neyOKfC4/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-36 - Allen Bradley 440R Guardmaster Safety Relay Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1fbIZuCEv6TwAFJyim8N8sQxI1edE5wj3ggxeDZHlPqM/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-37 - Allen Bradley 1734-FPD Field Potential Distributor Module Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1SZHjmAMrN6UnRVoxem6O3VQJJhYXUI1v5drg682SxW8/edit#gid=1521219698',
			},
			{
				type: 'SMP',
				description: 'SMP-38 - Allen Bradley Stratix 2000 Ethernet Switch Replacement',
				link: 'https://docs.google.com/spreadsheets/d/1ioHTLwT5k_87oU4d-pokEuGisUvrf3SxfOCKysPTZVM/edit#gid=1521219698',
			},

			{
				type: 'SOP',
				description: 'C10: Cleaning the Conveyor Rollers',
				link: 'https://docs.google.com/spreadsheets/d/17_m-__udTmSEY7JcJBixk5VRcdlt2BguVs4N9aeBOLo/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'C11: Cleaning the MultiControl Units',
				link: 'https://docs.google.com/spreadsheets/d/1uNrJO9IdPnhYKwbLUldalKKW6ducJwxQ7Cgva8VMtLY/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'C12: Cleaning The Photoelectric Through-Beam Sensors',
				link: 'https://docs.google.com/spreadsheets/d/1eqSVpSi1CyVBj6RHQS6qjCk5dGd97h4KX9Uw6GcdGp0/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'C13: Dry Clean Rough Top Conveyor Tops',
				link: 'https://docs.google.com/spreadsheets/d/1hiXQhSWFiW4GfrDbC7VMr8vbbmnSolwnMiPn8sexQg0/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'C14: Clean and Disinfect the HMI',
				link: 'https://docs.google.com/spreadsheets/d/1tcO2dvo73fMQUGWOKqVsVHR1kb_ALLgBII774rz4Nl4/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I10: Inspecting The Conveyor Power Rollers',
				link: 'https://docs.google.com/spreadsheets/d/12H76NTR2I2Fi2whrgg-5eyWVokon7R_cI0NzEcftV0I/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I11: Inspecting MultiControl Units',
				link: 'https://docs.google.com/spreadsheets/d/1zlbPI3KLV-k35ptVsJy-EQ6ZAdHvVGFDJwelLC2ldu8/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I12: Inspecting Conveyor Poly V-Belts',
				link: 'https://docs.google.com/spreadsheets/d/1SQ1hI2Pru6cu-3i1n9KiyhfcojUl64oZUi14l_-EJtQ/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I13: Inspecting 90 Degree Transfer Air Springs',
				link: 'https://docs.google.com/spreadsheets/d/1FMMuHPpQUzvwEc8eRfpDimRYdNIA_eiweAjWxu_jopU/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I14: Inspecting 90 Degree Transfer PowerTwist V-Belts',
				link: 'https://docs.google.com/spreadsheets/d/1Az9aLXbfQoMb7pJWiaBqS4cL4LvupgRRnFnlhVXrXIA/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I15: Inspecting Liftgate Gas Springs',
				link: 'https://docs.google.com/spreadsheets/d/1olMyOZmkFHJEm8-y9ttYYaNXYEyyqbx33psMdwtN7oE/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I16: Inspecting The Gear Motors',
				link: 'https://docs.google.com/spreadsheets/d/1SUjaAEH1Zoc0y6BesIoGeMbdmL6xfxTK1TVXPQjTBCU/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I17: Drive Chain Adjustment and Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1h4Ds5bxlPtBH3dqCkWp46pzE134af0PtFsKv71XL5nA/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I18: Base Autoload General Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1UhSnIua-adJCH4Erpb1z3Th9ttnUc0TdYgUwp9BswRQ/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I19: Inspecting Rough Top Conveyor Belts',
				link: 'https://docs.google.com/spreadsheets/d/1svDSzsR170GaeRof22kmY5xPRdFJ97mpXwayk-o2WgQ/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I20: Infeed Through Conveyor General Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1lW6L5hUUKWoe7I3WNL2xDuUuV3H02U8_aS2HK_fOm1s/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I21: Basket Takeaway Conveyor General Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1NHi96a68l08_phnKaCvRgSNCckiVaQAGB784LlBmBwg/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'I22: Base/Balancing Ring Conveyor General Inspection',
				link: 'https://docs.google.com/spreadsheets/d/1ugnj1RPdet9yeg_EAXf6vRewUzhU2g7TLJk5SVzwl0U/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L10: Lubricating Conveyor Flange Bearings',
				link: 'https://docs.google.com/spreadsheets/d/1tz3k7RfpgeN3bpc_Bmh9uudefmeXp3GBC8NBt0iae7c/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L11: Infeed Through Conveyor Gearmotor Oil Level Inspection SOP',
				link: 'https://docs.google.com/spreadsheets/d/1XTkbGV2VSidPLoZTUykVmDWv7qKvpBey77beq1tMLPM/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'L12: Infeed Through Conveyor Drive Chain Lubrication',
				link: 'https://docs.google.com/spreadsheets/d/10SqTJ0jHs_GkA4s3ZNtZzLrk1o3AjAbWfihIT1HAmfs/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'S10: HMI Emergency Stop Test',
				link: 'https://docs.google.com/spreadsheets/d/1dW-EYLyWBcmJI3-2RdnneM-7P-fXgi1GWsO2mlhwE1M/edit#gid=1791550664',
			},
			{
				type: 'SOP',
				description: 'S11: Conveyor 30 Amp Disconnect Switch Test',
				link: 'https://docs.google.com/spreadsheets/d/1xSH1R3lHF_s5UPm2dGuqKEuoQNQTx0N8sdD1UyR65zw/edit#gid=1791550664',
			},

			{
				type: 'CILR',
				description: 'Basket 11 CILR Map',
				link: Basket11CILR,
			},

			{
				type: 'LubeMap',
				description: 'Basket 11 Lubrication Map',
				link: 'https://docs.google.com/spreadsheets/d/1TyRc7DqxDIAW-6oHFO6aKf0fE3bHIWPNAfNn8XQZbfs/edit#gid=773944705',
			},

			{
				type: 'Calendar',
				description: 'Basket 11 AM Calendar',
				link: 'https://docs.google.com/spreadsheets/d/1F-mhUCCD-zIVU9dJer3KxXzGPK62tJysl2VoGsqafi4/edit#gid=765518518',
			},
		],
	},

	{
		name: 'Line 11B',
		documents: [
			{
				type: 'Ledger',
				description: 'Ajax Tocco Heat Treat Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1MGfatIzpzVW8T8IhbNmKPfKc2dtCGuQJBDbALWS_xpM',
			},
		],
	},



	{
		name: 'Bore Build Station',
		documents: [
			{
				description: 'Bore Build Station Ledger',
				type: 'Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1-nDjmAkBIsNRhO5e8lac1OLys7QELhvLaHtu9eEfSEg/edit#gid=205211224',
			},
		],
	},

	{
		name: 'Horizon Drum Line',
		documents: [
			{
				type: 'Ledger',
				description: 'Stations 1-6 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/12GukqjSZk0Q2sELaovYh8YIzEXGv9cl4BDOZGZ6kHg0/edit#gid=4630740',
			},
		],
	},

	{
		name: 'Milacron C1900-23000 Inj Press 24', //locked
		documents: [
			{
				type: 'Ledger',
				description: 'Milacron C1900-2300 Inj Press 24 Ledger',
				link: 'https://docs.google.com/spreadsheets/d/1uy6VQfN7Wr_y7wWsshRwMZuR9MYEB347kkbokTOaW2Y',
			},
		],
	},

	{
		name: 'Tub Welder 1', //locked
		documents: [
			{
				type: 'Ledger',
				description: 'Tub Welder 1 Ledger',
				link: 'https://drive.google.com/open?id=1D2buvKJsPNhHQ7pcr7xMvo9PnmHGZR9dNqhCFISN4Ec&pli=1&authuser=0',
			},
		],
	},

	{
		name: 'Tub Welder 2', //locked
		documents: [
			{
				type: 'Ledger',
				description: 'Tub Welder 2 Ledger',
				link: 'https://drive.google.com/open?id=1-9xSwuc9NVUJ2bNZjSkNY7pqPI3-64AiJoldSLBjeug',
			},
		],
	},
];

export default ClydeData;